import React from 'react'
import styled from 'styled-components'
import { styles } from '../styles/globals'
import Wrap from './layout/wrap'
import { Link } from 'gatsby'

import JHLogo from '../svgs/jh-logo-trademark.svg'

const StyledLink = styled(Link)`
    color: #fff;
`

const FooterEl = styled.footer`
    color: #fff;
    background-color: ${styles.bannerBackground};
    font-size: 0.875rem;
`

const StyledJHLogo = styled(JHLogo)`
    display: inline-block;
    width: 30px;
    height: 24px;

    @media (min-width: ${styles.desktopBreakpoint}) {
        margin-right: 1.25rem;
    }
`

const FooterWrap = styled(Wrap)`
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    text-align: center;

    @media (min-width: ${styles.desktopBreakpoint}) {
        flex-direction: row;
        text-align: left;
        justify-content: center;
    }
`

const FooterTextEl = styled.span`
    display: block;

    @media (min-width: ${styles.desktopBreakpoint}) {
        display: inline;
        margin-right: 1.25rem;
    }
`

const Footer = () => (
    <FooterEl>
        <FooterWrap skinny>
            <Link to="/">
                <StyledJHLogo />
            </Link>
            <span>
                <FooterTextEl>© 2020 Jawbone Health Hub, Inc.</FooterTextEl>
                <FooterTextEl>
                    <StyledLink to="/press">Press</StyledLink>
                </FooterTextEl>
                <FooterTextEl>
                    <a
                        target="_BLANK"
                        rel="noopener noreferrer"
                        href="https://jobs.lever.co/jawbonehealth"
                    >
                        {' '}
                        Careers
                    </a>
                </FooterTextEl>
                <FooterTextEl>
                    <StyledLink to="/privacy">Privacy Policy</StyledLink>
                </FooterTextEl>
            </span>
        </FooterWrap>
    </FooterEl>
)

export default Footer
