import { createGlobalStyle } from 'styled-components'
import '../../node_modules/normalize.css/normalize.css'
import './resets.css'

export const colors = {
    lightRed: '#E0B4B4',
    red: 'red',
    lightGrey: '#D3D3D0',
    darkGrey: '#383837',
    white: '#FFF'
}

export const styles = {
    colors: colors,
    maxWidth: '86%',
    footerHeight: '12rem',
    baseFontColor: colors.darkGrey,
    backgroundColor: colors.white,
    bannerBackground: '#242424',
    borderColor: colors.lightGrey,
    desktopBreakpoint: '62em'
}

export const GlobalStyles = createGlobalStyle`
    html {
        font-size: 16px;
        line-height: 1.5;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        box-sizing: border-box;
        scroll-behavior: smooth;
    }

    body {
        font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        height: 100%;
        color: ${styles.baseFontColor};
        background-color: ${styles.backgroundColor};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    a {
        color: #FFF;
        text-decoration: none;
    }

`

export default styles
