import styled from 'styled-components'
import { styles } from '../../styles/globals'

const Wrap = styled.div`
    width: 100%;
    max-width: ${styles.maxWidth};
    margin: 0 auto;

    @media (min-width: ${styles.desktopBreakpoint}) {
        max-width: ${props => (props.skinny ? '45rem' : '')};
    }
`

export default Wrap
