import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { GlobalStyles } from '../styles/globals'
import { Helmet } from 'react-helmet'
import Footer from '../components/footer'

import favicon from '../favicon.ico'
import appleicon from '../images/apple-touch-icon.png'

const PageWrapper = styled.div`
    height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-areas:
        'content'
        'footer';
`

const Main = styled.main`
    position: relative;
`

const DefaultLayout = ({ children }) => (
    <Fragment>
        <GlobalStyles />
        <Helmet>
            <title>Jawbone Health</title>
            <meta name="description" content="Health according to Jawbone" />
            <link rel="canonical" href="https://jawbonehealth.com/" />
            <link rel="shortcut icon" href={favicon} />
            <link rel="apple-touch-icon" href={appleicon} />
            <link rel="dns-prefetch" href="https://cloud.typography.com/" />
            <link
                rel="stylesheet"
                type="text/css"
                href="https://cloud.typography.com/7512656/7962592/css/fonts.css"
            />
        </Helmet>
        <PageWrapper>
            <Main>{children}</Main>
            <Footer />
        </PageWrapper>
    </Fragment>
)

DefaultLayout.propTypes = {
    children: PropTypes.element.isRequired,
}

export default DefaultLayout
